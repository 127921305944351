import card_3 from "@/shared/assests/ImageP_3.png";
import card_4 from "@/shared/assests/ImageP_4.png";
import card_5 from "@/shared/assests/ImageP_5.png";

export const list = [
    {
        title: "Статьи",
        listHref: [
            { title: "Подведение итогов пилотного тестирования сервиса для нетворкинга", href: "/" },
            { title: "Подведение итогов пилотного тестирования сервиса для нетворкинга", href: "/" },
            { title: "Подведение итогов пилотного тестирования сервиса для нетворкинга", href: "/" },
        ],
        image: card_3.src,
    },
    {
        title: "Новости",
        image: card_4.src,
        listHref: [
            { title: "Подведение итогов пилотного тестирования сервиса для нетворкинга", href: "/" },
            { title: "Подведение итогов пилотного тестирования сервиса для нетворкинга", href: "/" },
            { title: "Подведение итогов пилотного тестирования сервиса для нетворкинга", href: "/" },
        ],
    },
    {
        title: "Закон о рекламе",
        image: card_5.src,
        description:
            "Подведение итогов пилотного тестирования сервиса для нетворкинга. Подведение итогов пилотного тестирования сервиса для нетворкинга. Подведение итогов пилотного тестирования сервиса для нетворкинга",
    },
];

export const listQuestions = [
    {
        title: "Я могу выбрать конкретные дома для  размещения?",
        desc: "Да, в определенных пределах есть возможность осуществлять выборку,  каждый такой cлучай согласовывается с Заказчиком индивидуально.",
    },
    {
        title: "А законно стенды висят?",
        desc: "Все стенды висят законно согласно договоров с УК, ТСЖ и Застройщиком. Ознакомиться с ними можно в нашем офисе",
    },
    {
        title: "А могу я выбрать место на стенде?",
        desc: "Позиционирование на стенде -  платная опция, согласно договора 2025 года + 20% к стоимость размещения.",
    },
    {
        title: "Есть ли плавающие сроки по размещению рекламы в лифтах?",
        desc: "Планово размещение происходит 1 и 15-го числа каждого месяца. Возможна дополнительная смена макета при оплате печати и монтажных работ.",
    },
];
